import request from "@/api/request"

// 最近招聘会列表
export function jobFairApplyList(data) {
  return request({
    method: 'get',
    // url: '/main/jobFair/applyList',
    url: '/main/oauth/jobFair/applyList',
    params:data
  })
}

// 往期招聘会列表
export function jobFairPage(data) {
  return request({
    method: 'get',
    // url: '/main/jobFair/page',
    url: '/main/oauth/jobFair/page',
    params:data
  })
}

// 招聘会用户申请
export function userApply(data) {
  return request({
    method: 'post',
    url: '/main/jobFairUserApply/userApply',
    data:data
  })
}

// 根据id查询招聘会详情
export function jobFairDetail(data) {
  return request({
    method: 'get',
    // url: '/main/jobFair/detail',
    url: '/main/oauth/jobFair/detail',
    params:data
  })
}

// 根据招聘会ID获取报名企业
export function getApplyOrgPageForId(data) {
  return request({
    method: 'get',
    // url: '/main/jobFairApply/getApplyOrgPage',
    url: '/main/oauth/jobFairApply/getApplyOrgPage',
    params:data
  })
}

// 已报名招聘会列表
export function getjobFairPageByUser(data) {
  return request({
    method: 'get',
    url: '/main/jobFairUserApply/pageByUser',
    params:data
  })
}

// 查询是否报名当前招聘会
export function getjobFairSignUp(data) {
  return request({
    method: 'get',
    url: '/main/jobFairUserApply/page',
    params:data
  })
}

// 线上招聘会企业列表
export function getJobFairApplyOrg(data) {
  return request({
    method: 'get',
    url: '/main/jobFairApply/getApplyOrg',
    params:data
  })
}

// 线上招聘会职位列表
export function getJobFairPostOrg(data) {
  return request({
    method: 'get',
    url: '/main/jobFairPostApply/page',
    params:data
  })
}
