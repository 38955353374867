import request from "@/api/request"

// 扣除权益
export function subtractEquityNum(data) {
  return request({
    method: 'post',
    url: '/main/jobOwnerEquity/subtractEquityNum',
    data:data
  })
}

// 查询单个权益次数
export function oneEquityNum(data) {
  return request({
    method: 'post',
    url: '/main/jobOwnerEquity/oneEquityNum',
    data:data
  })
}

// 查看是否扣除过权益
export function oneEquityLogsNum(data) {
  return request({
    method: 'post',
    url: '/main/jobOwnerEquityLogs/oneEquityLogsNum',
    data:data
  })
}