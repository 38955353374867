<template>
  <div class="main">
    <HeaderTitle></HeaderTitle>
    <div class="bgc"></div>
    <div class="center c">
      <div class="centerLeft">
        <div class="title">
          <span></span>
          <span>招聘会信息</span>
          <el-divider></el-divider>
        </div>
        <div class="content">
          <div class="contentTitle">
            <p>{{ jobFair.title }}</p>
            <p>
              {{ jobFair.intro }}
            </p>
          </div>

          <div class="introduction">
            <div style="display: flex; justify-content: space-between">
              <div class="blueTitle">
                <span></span>
                <span>招聘会简介</span>
              </div>
              <el-button
                size="medium"
                :disabled="disabled"
                type="primary"
                v-if="held"
                >已举办</el-button
              >
              <!-- <el-button
                size="medium"
                @click="sginUpJobFair"
                :disabled="disabled"
                type="primary"
                v-else
                >{{ this.isSignUp ? '已报名' : '立即报名' }}</el-button
              > -->
              <div v-else>
                <div v-if="jobFair.fairType !== '2'">
                  <el-button
                  size="medium"
                  @click="jobLineUp(jobFair)"
                  type="primary"
                  v-if="timeMoment(jobFair)"
                  >进入会场</el-button
                >
                <el-button
                  v-else
                  size="medium"
                  :disabled="disabled"
                  type="primary"
                  @click="sginUpJobFair"
                  >{{ isSignUp ? "已报名" : "立即报名" }}</el-button
                >
                </div>
                <div v-else>
                  <el-button
                  size="medium"
                  type="primary"
                  v-if="timeMoment(jobFair)"
                  >{{ isSignUp ? "已报名" : "报名时间已截止" }}</el-button
                >
                <el-button
                  v-else
                  size="medium"
                  :disabled="disabled"
                  type="primary"
                  @click="sginUpJobFair"
                  >{{ isSignUp ? "已报名" : "立即报名" }}</el-button
                >
                </div>
              </div>
            </div>
            <div style="padding-top:20px"  v-html="jobFair.detail"></div>
          </div>

          <div class="wresTime">
            <div style="display: flex; justify-content: space-between">
              <div class="blueTitle">
                <span></span>
                <span class="wres">举办时间</span>
              </div>
            </div>
            <!-- <div v-html="jobFair.endApplyTime" style="margin-top: 5px"></div> -->
            <div style="padding-top:10px">{{ moment(jobFair.beginTime).format('yyyy-MM-DD HH:mm')}} - {{ moment(jobFair.endTime).format('yyyy-MM-DD HH:mm')}}</div>
          </div>

          <div class="registrationTime">
            <div style="display: flex; justify-content: space-between">
              <div class="blueTitle">
                <span></span>
                <span>报名时间</span>
              </div>
            </div>
            <div style="padding-top:10px">{{ moment(jobFair.beginApplyTime).format('yyyy-MM-DD HH:mm')}} - {{ moment(jobFair.endApplyTime).format('yyyy-MM-DD HH:mm')}}</div>
          </div>

          <div class="company">
            <div class="blueTitle">
              <span></span>
              <span>参展单位</span>
            </div>
            <div class="companyBox">
              <div
                class="companyItem"
                v-for="(item, index) in applyOrgList"
                :key="index"
              >
                <img :src="$imgUrl(item.logo)" alt="" />
                <!-- <img src="@/assets/jobFair/矩形 7.png" alt="" /> -->
                <span style="font-size: 14px">{{ item.name }}</span>
                <span style="font-size: 12px; color: #666"
                  >{{ getJobOrgType(item.properties) }} ·
                  {{ getJobGm(item.size) }}人 ·
                  {{ getOrgType(item.orgType) }}</span
                >
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- <div class="listRight">
          <div class="seeList">
            <div class="title">看过的人才</div>
            <div class="peopleList">
              <div class="peopleItem">
                <img src="@/assets/jobInformation/矩形 4.png" alt="" />
                <div class="peopleInfo">
                  <div class="name">
                    <span>丁冬冬</span>
                    <span>工龄8年</span>
                  </div>
                  <div class="tag">
                    <el-tag size="small">搬运工/装卸工</el-tag>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="recommend">
            <div class="title">给工人推荐</div>
            <div class="peopleList">
              <div class="peopleItem" v-for="(item, index) in 8" :key="index">
                <img src="@/assets/jobInformation/矩形 4.png" alt="" />
                <div class="peopleInfo">
                  <div class="name">
                    <span>丁冬冬</span>
                    <span>工龄8年</span>
                  </div>
                  <div class="tag">
                    <el-tag size="small">搬运工/装卸工</el-tag>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> -->
      <div class="centerRight">
        <div class="seeList">
          <div class="title">看过的职位</div>
          <div class="jobList">
            <div
              class="jobItem"
              v-for="(item, index) in postRedCollectList"
              :key="index"
              @click="toDatumInfo(item)"
            >
              <div class="jobTop">
                <span>{{ item.postName }}</span>
                <span>{{ item.pay ? getPay(item.pay) : '' }}</span>
              </div>
              <span class="jobBottom">{{ item.orgName }}</span>
            </div>
          </div>
        </div>
        <div class="relevant">
          <div class="title">热门搜索</div>
          <div class="relevantList">
            <div
              class="relevantItem"
              v-for="(item, index) in jobSearchKeyWord"
              :key="index"
              @click="searchForKeyWord(item.name)"
            >
              <span>{{ item.name }}</span>
              <i class="el-icon-arrow-right"></i>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Footer></Footer>
    <el-dialog title="提示" :visible.sync="dialogVisible" width="30%">
      <span>{{ tips }}</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogCancel">取 消</el-button>
        <el-button type="primary" @click="confirm(confirmType)"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import moment from 'moment'
import HeaderTitle from '@/components/hometitle/hometitle.vue'
import Footer from '@/components/footer/footers.vue'
import {
  jobFairDetail,
  getApplyOrgPageForId,
  userApply,
  getjobFairSignUp,
} from '@/api/jobFair/jobFair'
import { jobPostType } from '@/api/position/position'
import { subtractEquityNum, oneEquityNum } from '@/api/equity/equity.js'
import { jobPostRedCollectList, jobSearchPage } from '@/api/position/position'
export default {
  name: '',
  data() {
    return {
      jobUserId: '',
      dict: [],
      jobVipEquity: [],
      jobOrgType: [],
      jobFairId: '',
      jobGM: [],
      jobFair: {}, //招聘会详情
      applyOrgList: [], //参展单位列表
      tradeType: [], //单位类型
      held: '', //招聘会是否举办
      disabled: false, //按钮禁用
      isSignUp: false, //是否报名
      dialogVisible: false,
      tips: '',
      jobVipEquityId: '', //权益id
      confirmType: '',
      userId: '',
      jobPay: [], //薪资
      postRedCollectList: [], //看过的职位
      jobSearchKeyWord: '', //查询搜索关键字
    }
  },
  watch: {},
  props: {},
  components: {
    HeaderTitle,
    Footer,
  },
  computed: {},
  created() {
    this.jobUserId = localStorage.getItem('userId')
    this.dict = JSON.parse(localStorage.getItem('dict'))
    // this.jobVipEquity = this.dict.filter(
    //   i => i.code == 'job_vip_equity'
    // )[0].children
    this.jobOrgType = this.dict.filter(
      (i) => i.code == 'job_org_type'
    )[0].children
    this.jobPay = this.dict.filter((i) => i.code == 'job_pay')[0].children
    this.jobGM = this.dict.filter((i) => i.code == 'job_gm')[0].children
    this.jobFairId = this.$route.query.id
    this.held = this.$route.query.held ? true : false
    this.disabled = this.held ? true : false
    this.getJobFairDetail()
    this.getApplyOrgList()
    this.getJobTradeType()
    this._getjobFairSignUp()
    // 看过的职位
    this.getJobPostRedCollectList()
    // 相关搜索关键字
    this.getJobSearchPage()
  },
  mounted() {},
  methods: {
    moment,
    // 看过的职位
    getJobPostRedCollectList() {
      let params = {
        type: '1',
        pageNo: 1,
        pageSize: 3,
      }
      this.jobUserId
        ? (params.userId = this.jobUserId)
        : (params.ip = returnCitySN['cip'])
      jobPostRedCollectList(params).then((res) => {
        console.log(res, '看过的职位')
        this.postRedCollectList = res.data.rows
      })
    },
    getPay(code) {
      return this.jobPay.filter((i) => i.code == code)[0].name;
    },
    timeMoment(val) {
      let nowTime = moment(new Date()).format("YYYY-MM-DD HH:mm:ss");
      let beginTime = moment(val.beginTime).format("YYYY-MM-DD HH:mm:ss");
      let endTime = moment(val.endTime).format("YYYY-MM-DD HH:mm:ss");
      if (beginTime <= nowTime && nowTime <= endTime) {
        return true;
      } else {
        return false;
      }
    },
    // 查询搜索关键字
    getJobSearchPage() {
      jobSearchPage({ pageSize: 10 }).then((res) => {
        this.jobSearchKeyWord = res.data.rows
      })
    },
    // 查看职位详情
    toDatumInfo(item) {
      this.$router.push({
         path: '/datum',
         query: { id: item.postId },
      })
    },
    // 相关搜索
    searchForKeyWord(keyWord) {
      this.$router.push({
         path: '/position',
         query: { keyWord: keyWord },
      })
    },
    // 查询是否报名
    _getjobFairSignUp() {
      getjobFairSignUp({ jobUserId: localStorage.getItem('userId') }).then(
        (res) => {
          let data = res.data.rows
          this.isSignUp = data.some((i) => i.jobFairId == this.jobFairId)
          if (this.isSignUp) {
            this.disabled = true
          }
        }
      )
    },
    // 获取行业类型
    getJobTradeType() {
      jobPostType({ code: 'trade_type' }).then((res) => {
        this.tradeType = res.data[0].children
      })
    },
    // 根据单位类型id回显  递归找父级id
    // getOrgTypeForTreer(list, id) {
    //   var ids = []
    //   for (let i = 0; i < list.length; i++) {
    //     if (list[i].id == id) {
    //       if (list[i].pid != '1537642960810602497') {
    //         ids.push(list[i].pid)
    //       }
    //       ids.push(list[i].id)
    //       break
    //     } else if (list[i].children && list[i].children.length > 0) {
    //       ids = this.getOrgTypeForTreer(list[i].children, id)
    //       if (ids.length > 0) return ids
    //     }
    //   }
    //   return ids
    // },
    // 招聘会详情
    getJobFairDetail() {
      jobFairDetail({ id: this.jobFairId }).then((res) => {
        this.jobFair = res.data
      })
    },
    // 参展单位
    getApplyOrgList() {
      let params = { jobFairId: this.jobFairId, auditStatus: '2' }
      getApplyOrgPageForId(params).then((res) => {
        console.log(res, '参展单位')
        this.applyOrgList = res.data.rows
      })
    },
    getOrgType(str) {
      let orgType = str.split(',')
      let arr = []
      orgType.forEach((i) => {
        arr.push(this.getOrgTypeForId(this.tradeType, i))
      })
      return arr.join('/')
    },
    getOrgTypeForId(list, id) {
      var title = ''
      for (let i = 0; i < list.length; i++) {
        if (list[i].id == id) {
          title = list[i].title
          break
        } else if (list[i].children && list[i].children.length > 0) {
          title = this.getOrgTypeForId(list[i].children, id)
          if (title) return title
        }
      }
      return title
    },
    // 单位类型
    getJobOrgType(code) {
      return this.jobOrgType.filter((i) => i.code == code)[0].name
    },
    // 单位规模
    getJobGm(code) {
      console.log(code, "单位规模");
      return this.jobGM.filter((i) => i.code == code)[0].name;
    },
    jobLineUp(val) {
      getjobFairSignUp({ jobUserId: localStorage.getItem("userId") }).then(
        (res) => {
          let data = res.data.rows;
          let isSignUp = data.some((i) => i.jobFairId == this.jobFairId);
          if (isSignUp) {
            this.$router.push({
                path: '/jobLineDetails',
                query: { id: val.id },
            })
          } else {
            this.$message.warning("您未在报名期间报名,暂无法参与线上招聘会");
          }
        }
      );
    },
    sginUpJobFair() {
      let userId = localStorage.getItem('userId')
      if (!userId) {
        this.$confirm('暂未登录，请先前往登录!', '', {
          confirmButtonText: '登录',
          showCancelButton: false,
          type: 'warning'
        }).then(() => {
         this.$router.push('/login')
        }).catch(() => {});
        return
      } else {
        let beginApplyTime = this.jobFair.beginApplyTime
        let endApplyTime = this.jobFair.endApplyTime

        // 报名时间在当前时间前面，报名已开始
        // let ApplyFlag = moment(beginApplyTime).isBefore(new Date())
        let ApplyFlag = false
        let curDate = new Date()
        let beginDate = new Date(beginApplyTime)
        let endDate = new Date(endApplyTime)
        if (curDate >= beginDate && curDate <= endDate) {
                ApplyFlag = true
          }
        if(ApplyFlag) {
          let endApplyTime = moment()
          this._userApply()
        } else {
          this.$message.warning('报名未开始/已结束')
        }
        // this._userApply()
        // this.getEquityNum();
      }
      // this.jobVipEquityId = this.jobVipEquity.filter(i => i.code == 1)[0].name
      // this.getEquityNum(this.jobUserId,this.jobVipEquityId)
    },
    // 查询是否扣除过权益
    // _oneEquityLogsNum() {
    //   let obj = {
    //     operationId: this.jobFairId,
    //   }
    //   if(this.jobUserId) {
    //     obj.jobUserId = this.jobUserId
    //     obj.index = 7
    //     obj.userType = 1
    //   }
    //   oneEquityLogsNum(obj).then(res => {
    //     console.log(res,'是否扣除过权益');
    //     if(res.success && res.data.status) {
    //       console.log('扣除过权益');
    //       // this.toSee()
    //     }
    //   })
    // },
    // 获取权益次数
    getEquityNum() {
      let obj = {
        jobUserId: this.jobUserId,
        index: 7,
        userType: 1,
      }
      oneEquityNum(obj).then((res) => {
        console.log(res, '权益次数')
        if (res.success) {
          this.dialogVisible = true
          if (res.data == 0) {
            this.tips = '当前权益次数不足，是否前往充值？'
            this.confirmType = 1
          } else {
            this.tips = '报名招聘会将扣除您的相关权益，是否报名？'
            this.confirmType = 2
          }
        }
      })
    },

    // 确定对话框
    confirm(confirmType) {
      // 1:前往充值 2:花权益购买
      if (confirmType == 1) {
        this.$router.push('/personalCenter/memberCenter')
      } else if (confirmType == 2) {
        // 扣除权益
        // this._subtractEquityNum()
        // 报名
        this._userApply()
      } else if (confirmType == '') {
        this.dialogVisible = false
      }
    },

    // 取消对话框
    dialogCancel() {
      this.dialogVisible = false
      this.confirmType = ''
    },

    // 扣除权益
    _subtractEquityNum() {
      this.dialogVisible = false
      let obj = {
        jobUserId: this.jobUserId,
        operationId: this.jobFair.id,
        subtractNum: 1,
        index: 7,
        userType: 1,
      }
      subtractEquityNum(obj).then((res) => {
        if (res.data) {
          this.dialogVisible = false
          console.log('权益扣除成功')
        } else {
          this.dialogVisible = false
          console.log('权益扣除失败')
        }
      })
    },
    // 报名
    _userApply() {
      let obj = {
        jobFairId: this.jobFair.id,
        jobUserId: localStorage.getItem('userId'),
      }
      userApply(obj).then((res) => {
        console.log(res, '招聘会报名')
        if (res.code == 200) {
          this._getjobFairSignUp()
          // this._subtractEquityNum();
          this.$message({
            message: res.message,
            type: 'success',
          })
        } else {
          this.$message.error(res.message)
        }
      })
    },
  },
  filters: {},
}
</script>

<style scoped lang="scss">
.c {
  width: 1200px;
  margin: 0 auto;
}

.bgc {
  height: 350px;
  background: url('~@/assets/jobFairDetails/bgc.png');
  position: relative;
  z-index: -1;
}
.bgc::after {
  position: absolute;
  content: '';
  width: 790px;
  height: 530px;
  top: 30%;
  left: 30%;
  transform: translate(-50%, -50%);
  background: url('~@/assets/jobFairDetails/text.png');
}

.pIndex {
  text-indent: 4em;
}
.pFont {
  color: #666;
  font-size: 14px;
}

.center {
  display: flex;
  justify-content: space-between;
  margin-top: -70px;
  margin-bottom: 100px;
}

.blueTitle {
  display: flex;
  align-items: center;
  span:nth-child(1) {
    width: 16px;
    height: 16px;
    background-color: #0a96f3;
    margin-right: 10px;
  }
  span:nth-child(2) {
    color: #0a96f3;
  }
}

.centerLeft {
  width: 894px;
  background-color: #fff;
  padding-top: 30px;
  .title {
    display: flex;
    align-items: center;
    span:nth-child(1) {
      width: 10px;
      height: 30px;
      background-color: #0a96f3;
    }
    span:nth-child(2) {
      font-size: 18px;
      margin: 0 20px 0 26px;
    }
    .el-divider {
      flex: 1;
      margin: 0;
    }
  }
  .content {
    padding: 0 60px;
    .contentTitle {
      display: flex;
      flex-direction: column;
      align-items: center;
      p {
        margin: 0;
        padding: 0;
      }
      p:nth-child(1) {
        font-size: 26px;
        margin-bottom: 30px;
        margin-top: 30px;
      }
      p:nth-child(2) {
        color: #666;
        text-indent: 2em;
        margin-bottom: 20px;
        width: 100%;
      }
    }
    .introduction {
      margin-bottom: 30px;
      .btn {
        width: 120px;
        height: 38px;
        background-color: #0099ff;
        text-align: center;
        line-height: 38px;
        color: #fff;
        border-radius: 4px;
        font-size: 14px;
        cursor: pointer;
      }
    }

    .wresTime {
      margin-bottom: 30px;
    }

    .registrationTime {
      margin-bottom: 30px;
    }
    .company {
      .companyBox {
        display: flex;
        flex-wrap: wrap;
        // justify-content: space-between;
        padding-top: 30px;
        padding-bottom: 20px;
        .companyItem {
          width: 184px;
          height: 120px;
          box-shadow: 0px 0px 3px #eee;
          margin-bottom: 14px;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          margin-right: 8px;
          img {
            width: 60px;
            height: 60px;
          }
          span {
            // width: 100%;
            // white-space: nowrap;
            // overflow: hidden;
            // text-overflow: ellipsis;
            margin: 4px 0;
            -webkit-line-clamp: 1;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            overflow: hidden;
            padding: 0 10px;
          }
        }
      }
    }
  }
}

.centerRight {
  width: 282px;
  .seeList,
  .relevant {
    background-color: #fff;
    margin-bottom: 16px;
    padding-bottom: 10px;
    .title {
      height: 48px;
      font-size: 14px;
      color: #029aff;
      line-height: 48px;
      padding: 0 20px 0 24px;
      border-bottom: 1px solid #e8e8e8;
    }
    // .peopleList {
    //   padding: 0 20px 0 24px;
    //   .peopleItem:last-child {
    //     border-bottom: none;
    //   }
    //   .peopleItem {
    //     display: flex;
    //     padding: 10px 0;
    //     border-bottom: 1px solid #f2f2f2;
    //     img {
    //       width: 48px;
    //       height: 48px;
    //       margin-right: 20px;
    //     }
    //     .peopleInfo {
    //       flex: 1;
    //       display: flex;
    //       flex-direction: column;
    //       justify-content: space-between;
    //       .name {
    //         display: flex;
    //         justify-content: space-between;
    //         span:nth-child(1) {
    //           font-size: 14px;
    //         }
    //         span:nth-child(2) {
    //           font-size: 12px;
    //           color: #666;
    //         }
    //       }
    //       .tag {
    //         font-size: 12px;
    //       }
    //     }
    //   }
    // }
  }
  .seeList {
    .jobList {
      padding: 0 20px 0 24px;
      .jobItem {
        padding: 10px 0;
        .jobTop {
          display: flex;
          justify-content: space-between;
          margin-bottom: 10px;
          span:nth-child(1) {
            font-size: 14px;
          }
          span:nth-child(2) {
            font-size: 14px;
            color: #ff8d19;
          }
        }
        .jobBottom {
          font-size: 12px;
          color: #a8a8a8;
        }
      }
    }
  }
  .relevant {
    .title {
      height: 48px;
      font-size: 14px;
      color: #029aff;
      line-height: 48px;
      padding: 0 20px 0 24px;
      border-bottom: 1px solid #e8e8e8;
    }
    .relevantList {
      padding: 0 20px 0 24px;
      .relevantItem {
        height: 50px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid #e8e8e8;
        cursor: pointer;
        span {
          font-size: 14px;
        }
        i {
          font-size: 12px;
          color: #818a92;
        }
      }
      .relevantItem:nth-last-child(1) {
        border-bottom: none;
      }
    }
  }
}
</style>
